<template>
  <section>
    <div class="background-section-title">
      <div class="section-title">
        <h2>Transcrições</h2>
      </div>
      </div>
      <div class="section-content">
      <div class="list-container">
        <div class="vld-parent">
        <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"></loading>
        </div>
          <div class="header-container shadow-sm p-3 mb-5 bg-white rounded">
              <b-button class="new-user-button" @click="newTranscription()">
                  Nova transcrição
              </b-button>
              <b-form-radio-group
                      v-model="selected"
                      class="search-filter"
                      button-variant="outline-primary"
                      buttons
              >
                  <b-form-radio value="name" v-b-tooltip.hover title="Nome da transcrição">Nome</b-form-radio>
                  <b-form-radio value="content" v-b-tooltip.hover title="Conteúdo">Conteúdo</b-form-radio>
                  <b-form-radio value="date" v-b-tooltip.hover title="Formato aceito: 15(dia), 2020, 15/01/2020">Data</b-form-radio>
              </b-form-radio-group>
              <div class="search-container">
                  <b-input-group size="sm" class="mb-0">
                      <b-input-group-prepend is-text>
                          <b-icon icon="search"></b-icon>
                      </b-input-group-prepend>
                      <b-form-input v-model="filterText" type="search" v-on:keyup.enter="onSearchClick()" placeholder="Buscar..."></b-form-input>
                  </b-input-group>
              </div>
          </div>
      <b-table class="shadow-sm bg-white rounded" striped :fields="fields" :items="transcripts" :per-page="0" :current-page="currentPage" stacked="md">
          <template v-slot:cell(name)="row">
              <div>{{row.value}}</div>
              <div v-if="row.item.content" style="font-family: Arial"><i> {{formatSearchedText(row.item.content)}} </i></div>
          </template>
        <template v-slot:cell(status)="row">
          <img :src="getStatusIconStyle(row.item)"  v-b-tooltip.hover :title="getStatusIconTooltip(row.item)">
        </template>
        <template v-slot:cell(edit)="row">
          <img class="item-table" :src="editIcon" @click="openTranscriptionDetail(row.item, row.index, $event.target)">
        </template>
        <template v-slot:cell(remove)="row">
          <img class="item-table" :src="deleteIcon" @click="deleteTranscription(row.item, row.index, $event.target)">
        </template>
      </b-table>
          <div class="pagination-container shadow-sm bg-white rounded">
              <b-row>
                  <b-col class="my-3">
                      <b-pagination @input ="updatePage()" v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
                  </b-col>
                  <b-col  class="my-3">
                      <b-form-select @input ="updatePage()" style="min-width: 100px" id="per-page-select" v-model="perPage" :options="pageOptions"></b-form-select>
                  </b-col>
              </b-row>
          </div>
      </div>
      <b-modal ref="modal" title="" @hidden="resetModal" cancel-variant="transparent" size="lg" hide-footer>
        <template #modal-title>
          <h4 class="mt-2 pb-0">Nova transcrição</h4>
        </template>
        <div>
          <b-form v-if="showTranscriptionTab">
            <b-form-group class="tab-bold" label="Nome da transcrição" label-for="name-input" :state="transcriptionNameState" :invalid-feedback="transcriptionNameInvalidFeedback">
              <b-form-input :state="transcriptionNameState" id="name-input" v-model="transcription.name"></b-form-input>
            </b-form-group>
            <b-form-group class="tab-bold" label="Descrição da transcrição" label-for="monitor-description">
              <b-form-input id="monitor-description" v-model="transcription.description"></b-form-input>
            </b-form-group>
            <b-form-group :state="Boolean(file)" invalid-feedback="É necessário selecionar um arquivo">
              <b-form-file v-model="file" :state="Boolean(file)" :placeholder="fileName" accept="audio/mpeg, audio/mp3, audio/aac, video/mp4"
                        browse-text="Escolha o arquivo"
                           @input="changeFile()"></b-form-file>
            </b-form-group>
            <div v-show="showFileProperties">
              <b-form inline>
                <b-form-checkbox class="multichannelAudio mt-3" value="accepted" v-model="multiChannelChecked" unchecked-value="not_accepted" @change="onChangeMultiChannelCheckBox($event)">
                            Áudio multicanal
                </b-form-checkbox>
                <img src="@/assets/icons/icone_i.svg" v-b-tooltip.hover.right :title="audioInformation" ref="showAfterFile" style="margin-top: 5px; margin-left: 8px;"/>
                <p v-show="showDiarization" class="tab mt-4" disabled style="margin-left: 370px">Locutores:</p>
                <b-form-input v-show="showDiarization" class="mt-2" type="number" min="1" max="10" style="font-size:17px; line-height: 20px; height: 30px; margin-left: 18px; margin-top: -18px; width: 84px !important; border-radius: 2px;"></b-form-input>
              </b-form>
            </div>
          </b-form>
          <b-form v-if="!showTranscriptionTab">
            <b-form-group class="tab mt-4">
              <p class="aboutAccuracy" style="font-weight: 600; margin-bottom: 30px !important">Canais de áudio (multicanal)</p>
              <div v-for="(item, index) in audioChannelCount" :key="index">
                <b-form inline>
                  <b-button :style="channelStyle(item.channel)" disabled>{{item.channel}}</b-button>
                  <b-form-input type="text" v-model="audioChannelCount[index].announcer" :placeholder=item.announcer class="channels"></b-form-input>
                </b-form>
                <br>
              </div>
            </b-form-group>
          </b-form>
          <b-form>
            <div v-show="file != null">
              <b-progress height="13px" style="background-color: #DEF1FF; margin-top: 15px" :value="this.$store.state.transcription.percentCompleted" show-progress class="mb-2"></b-progress>
             <img :src="imgStatusAccuracy"/>
              <p class="tab" style="margin-top: 15px; margin-left: 10px; display: inline-block; line-height: 20px">Potencial de <span style="font-weight: bold">{{ accuracyStatus }}</span></p>
              <p class="tab" style="display: inline-block; font-size: 14px; margin-left: 275px">Duração do áudio: <span style="font-weight: bold"> {{formatedFileDuration}}</span></p>
              <div>
                <b-link @click="onKnowMoreClick()" class="knowMore" >Saber mais</b-link>
                <img src="@/assets/icons/arrow.svg" style="margin-left: 5px"/>
              </div>
            </div>
            <div class="col-sm-12 bottom-bar">
              <ul class="list-inline float-right">
                <li class="list-inline-item">
                  <b-button class="btn-secondary btn-transparent" @click="onButtonBackActionClick()">{{btnBackAction}}</b-button>
                  <b-button type="button"
                            @click="onButtonNextActionClick()"
                            class="btn-primary" style="width: 134px">{{btnNextAction}}</b-button>
                </li>
              </ul>
            </div>
          </b-form>
        </div>
      </b-modal>
      <b-modal class="body" ref="accuracyModal" :ok-title="submitTitle" cancel-title="Cancelar" cancel-variant="transparent" @ok="handleModalOk" hide-footer>
      <h4 class="ml-0">Conte-nos sobre o que você deseja transcrever:</h4>
      <b-form-group class="tab">
         <b-form-checkbox v-for="option in options" @change="onAccuracySelectedChange(event)" v-model="selecteds" :key="option.value" :value="option.value">
        {{ option.text }}
      </b-form-checkbox>
      <img :src="imgStatusAccuracy"/>
        <p class="" style="margin-top: 15px; margin-left: 10px; display: inline-block; line-height: 20px">Potencial de <span style="font-weight: bold">{{ accuracyStatus }}</span></p>
        <p class="aboutAccuracy mt-4">Saiba mais sobre o assunto em <a :href="site" target="blanc">www.retextoar.com.br</a></p>
      </b-form-group>
      <footer>
        <b-button class="btn-primary btn-close" @click="hideAcurracyModal()">Voltar a transcrição</b-button>
      </footer>
      </b-modal>
    </div>
  </section>
</template>

<script>
  import { transcriptionService } from '../services/transcription.service';
  import { userService } from '../services/user_service';
  import { mapActions, mapState } from 'vuex';
  import Loading from 'vue-loading-overlay';
  import deleteIcon from '@/assets/icons/delete.svg';
  import editIcon from '@/assets/icons/pencil.svg';
  import transcribingIcon from '@/assets/icons/transcribing.svg';
  import doneIcon from '@/assets/icons/done.svg';
  import errorIcon from '@/assets/icons/erro_triangle.svg';
  import alertTriangle from '@/assets/icons/alert-triangle.svg';
  import timeIcon from '@/assets/icons/pending.svg';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import moment from 'moment';
  import config from '../config/config.json';
  import { formatingSecondToDate } from '../helpers/server';
  export default {
    components: {
      Loading
    },
    data () {
      return {
        alertTriangle,
        imgStatusAccuracy: '',
        accuracyStatus: '',
        showTranscriptionTab: true,
        multiChannelChecked: 'not_accepted',
        showFileProperties: false,
        showDiarization: false,
        transcriptionNameInvalidFeedback: 'Nome é necessário',
        transcribingIcon,
        doneIcon,
        errorIcon,
        timeIcon,
        deleteIcon,
        formatedFileDuration: '00:00:00',
        editIcon,
        transcripts: [],
        metadata: [],
        pageInfo: {},
        modalTitle: 'Nova transcrição',
        transcriptionNameState: null,
        file: null,
        audioDuration: 0,
        audioChannelCount: [],
        pageOptions: [5, 10, 15, { value: 100, text: 'Mostrar 100' }],
        transcription: {
          name: '',
          description: '',
          inputFile: ''
        },
        fields: [
          {
            key: 'name',
            label: 'Nome',
            sortable: true,
            formatter: (value, key, item) => {
              return (value.length > 80) ? value.substr(0, 80) + '...' : value;
            }
          },
          {
            key: 'date.created',
            label: 'Data',
            sortable: true,
            formatter: (value, key, item) => {
              return moment(Number(value)).format('DD/MM/YYYY');
            },
            thStyle: { width: '220px' }
          },
          {
            key: 'status',
            label: 'Status',
            alias: 'status',
            sortable: false,
            thStyle: { width: '150px' }
          },
          { key: 'edit', label: '', sortable: false, thStyle: { width: '50px' } },
          { key: 'remove', label: '', sortable: false, thStyle: { width: '50px' } }
        ],
        isLoading: false,
        fullPage: true,
        timer: '',
        selected: 'name',
        filterText: '',
        refreshTimer: 15000,
        totalRows: 0,
        perPage: 15,
        btnNextAction: 'Transcrever',
        btnBackAction: 'Fechar',
        currentPage: 1,
        mounted: false,
        progressValue: 0,
        selecteds: [],
        options: [
          { text: 'O áudio está sem ruído?', value: 'withoutNoise' },
          { text: 'Os locutores estão próximos ao microfone?', value: 'nextEachOther' },
          { text: 'Os locutores falam um de cada vez?', value: 'oneAtTime' },
          { text: 'Os locutores falam claramente?', value: 'clearly' }
        ],
        audioInformation: 'Selecione essa opção caso seu áudio possua múltiplas fontes de som, independentes entre sí. Caso tenha dúvidas sobre o tipo de áudio, não selecione a opção. Mais informações em retextoar.com.br',
        announcer1: 'Locutor 01',
        announcer2: 'Locutor 02',
        announcer3: 'Locutor 03',
        submitTitle: 'Ok',
        fileName: 'Sem arquivo de fonte',
        site: 'https://www.digitro.com/solucao/retextoar'
      };
    },
    computed: {
      ...mapState({
        account: state => state.account
      })
    },
    mounted () {
      this.mounted = true;
      if (this.account.permissions.includes('DIARIZACAO')) {
        this.showDiarization = true;
      }
      this.showTranscriptionTab = true;
      this.multiChannelChecked = 'not_accepted';
      this.accuracyStatus = 'baixa acuracidade';
      this.imgStatusAccuracy = this.alertTriangle;
    },
    async created () {
      await this.loadTranscripts({ perPage: parseInt(this.perPage), currentPage: this.currentPage });
      this.timer = setInterval(this.autoRefresh, this.refreshTimer);
    },
    beforeDestroy () {
      clearInterval(this.timer);
    },
    watch: {
      'transcription.name' () {
        this.transcriptionNameState = this.transcription.name.length >= 4;
      }
    },
    methods: {
      ...mapActions('account', ['balance']),
      async onAccuracySelectedChange () {
        if (this.selecteds.length === 4) {
          this.accuracyStatus = 'alta acuracidade';
          this.imgStatusAccuracy = this.doneIcon;
        } else {
          this.accuracyStatus = 'baixa acuracidade';
          this.imgStatusAccuracy = this.alertTriangle;
        }
      },
      async loadTranscripts (params) {
        this.isLoading = true;
        const result = await transcriptionService.getMetadataTranscriptions(params);
        if (result) {
          this.transcripts = result.transcripts;
          this.pageInfo = result.pageInfo;
          this.totalRows = this.pageInfo && this.pageInfo.length > 0 && this.pageInfo[0].count;
        }
        this.isLoading = false;
      },
      async autoRefresh () {
        await this.balance();
        await this.loadTranscripts({ perPage: parseInt(this.perPage), currentPage: this.currentPage });
      },
      formatSearchedText (content) {
        return content.replace(this.filterText, `"${this.filterText}"`);
      },
      updatePage () {
        if (this.mounted) {
          this.loadTranscripts({ perPage: parseInt(this.perPage), currentPage: this.currentPage });
        }
      },
      onChangeMultiChannelCheckBox (checked) {
        if (checked === 'accepted') {
          this.btnNextAction = 'Avançar';
        } else {
          this.btnNextAction = 'Transcrever';
        }
        this.btnBackAction = 'Fechar';
      },
      onSearchClick () {
        if (this.mounted) {
          if (this.selected === 'name' && this.filterText.length > 2) {
            clearInterval(this.timer);
            this.loadTranscripts({ perPage: parseInt(this.perPage), currentPage: parseInt(this.currentPage), name: this.filterText.toLowerCase() });
          } else if (this.selected === 'date' && this.filterText.length > 1) {
            clearInterval(this.timer);
            this.loadTranscripts({ perPage: parseInt(this.perPage), currentPage: parseInt(this.currentPage), date: moment(this.filterText, 'DD/MM/YYYY HH:mm').unix() });
          } else if (this.selected === 'content' && this.filterText.length > 1) {
            clearInterval(this.timer);
            this.loadTranscripts({ perPage: parseInt(this.perPage), currentPage: parseInt(this.currentPage), content: this.filterText.toLowerCase() });
          } else {
            this.timer = setInterval(this.autoRefresh, this.refreshTimer);
            this.loadTranscripts({ perPage: parseInt(this.perPage), currentPage: parseInt(this.currentPage) });
          }
        }
      },
      getStatusIconStyle (transcription) {
        if (transcription.status === 'completed') {
          return this.doneIcon;
        } else if (transcription.status === 'transcribing') {
          return this.transcribingIcon;
        } else if (transcription.status === 'failed' || transcription.status === 'error') {
          return this.errorIcon;
        } else if (transcription.status === 'waiting' || transcription.status === 'pending' || transcription.status === 'receiving') {
          return this.timeIcon;
        }
      },
      getStatusIconTooltip (transcription) {
        if (transcription.status === 'completed') {
          return 'Finalizada';
        } else if (transcription.status === 'transcribing') {
          return 'Transcrevendo...';
        } else if (transcription.status === 'failed' || transcription.status === 'error') {
          return 'Falhou';
        } else if (transcription.status === 'waiting' || transcription.status === 'pending' || transcription.status === 'receiving') {
          return 'Pendente';
        }
      },
      channelStyle (channelId) {
        let style = '';
        if (channelId === 1) {
          style = 'letter-spacing: 4px; background-color: #EC7532; border: none; width: 48px';
        } else if (channelId === 2) {
          style = 'letter-spacing: 4px; background-color: #A9AE47; border: none; width: 48px';
        } else if (channelId === 3) {
          style = 'letter-spacing: 4px; background-color: #DEF1FF; border: none; width: 48px';
        } else if (channelId === 4) {
          style = 'letter-spacing: 4px; background-color: #C3272E; border: none; width: 48px';
        }
        return style;
      },
      async newTranscription () {
        clearInterval(this.timer);
        const permissions = this.$store.state.account.permissions;
        if (permissions && !permissions.includes('SOLICITAR_TRANSCRICAO')) {
          this.$toast.open({ message: 'Usuário não possui permissão!', type: 'error' });
          return;
        }
        this.$refs.modal.show();
      },
      checkFormValidity () {
        this.transcriptionNameState = this.transcription.name.length >= 4;
        if (this.transcription.name.length < 5 && this.transcription.name.length > 0) {
          this.transcriptionNameInvalidFeedback = 'Mínimo de 4 caracteres';
        } else {
          this.transcriptionNameInvalidFeedback = 'Nome é necessário';
        }
        return this.transcriptionNameState && this.file;
      },
      resetModal () {
        this.transcription.name = '';
        this.transcription.description = '';
        this.transcriptionNameState = null;
        this.file = null;
        this.timer = setInterval(this.autoRefresh, this.refreshTimer);
        this.$store.state.transcription.percentCompleted = 0;
        this.showFileProperties = false;
        this.audioChannelCount = [];
        this.showTranscriptionTab = true;
        this.multiChannelChecked = 'not_accepted';
      },
      handleModalOk (bvModalEvt) {
        this.handleSubmit();
      },
      async handleSubmit (e) {
        this.submitted = true;
        if (!this.checkFormValidity()) {
          return;
        } else if (Number(this.audioDuration) <= 0) {
          this.$toast.open({ message: 'Não foi possivel identificar a mídia!', type: 'error' });
          return;
        }
        const balance = await userService.creditsInfo();
        if (balance) {
          const creditsTotal = Number(balance.credits);
          const reservedCredits = Number(balance.reservado);

          const creditsAvailable = creditsTotal - reservedCredits;
          if (creditsAvailable >= Number(this.audioDuration)) {
            const newTranscription = {
              name: this.transcription.name,
              description: this.transcription.description,
              duration: this.audioDuration,
              type: 'automatic',
              numOfSpeakers: 1
            };
            this.isLoading = true;
            const res = await transcriptionService.registerTranscription(newTranscription);
            if (res.status === 402) {
              this.$toast.open({ message: 'Créditos insuficientes!', type: 'warning' });
              this.isLoading = false;
              this.$nextTick(() => {
                this.$refs.modal.hide();
              });
              this.resetModal();
              return;
            }
            const transcriptionInfo = await res.json();
            const valId = res.headers.get('x-val-id');

            await transcriptionService.uploadMedia(transcriptionInfo.id, this.file, valId);
            this.$toast.open('Transcrição enviada com sucesso!');
            this.isLoading = false;
            await this.loadTranscripts({ perPage: parseInt(this.perPage), currentPage: this.currentPage });
            this.$store.state.transcription.percentCompleted = 0;
            this.$nextTick(() => {
              this.$refs.modal.hide();
            });
            this.resetModal();
          } else {
            this.resetModal();
            this.$toast.open({ message: 'Créditos insuficientes!', type: 'warning' });
          }
        }
      },
      async deleteTranscription (transcription) {
        clearInterval(this.timer);
        this.$bvModal.msgBoxConfirm(`Tem certeza que deseja remover ${transcription.name} ?`, {
          size: 'md',
          buttonSize: 'md',
          okVariant: 'primary',
          okTitle: 'Excluir',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(async (value) => {
          if (value) {
            this.isLoading = true;
            const res = await transcriptionService.deleteTranscription(transcription.id);
            if (res.status === 200) {
              this.$toast.open('Transcrição removida com sucesso!');
              await this.loadTranscripts({ perPage: parseInt(this.perPage), currentPage: this.currentPage });
            } else if (res.status === 403) {
              this.isLoading = false;
              this.$toast.open({ message: 'Não é possivel remover essa transcrição!', type: 'error' });
            }
          }
        }).catch((err) => {
          this.isLoading = false;
          this.$toast.open({ message: 'Falha ao remover!', type: 'error' });
          console.log(err);
        });
        this.resetModal();
        this.isLoading = false;
      },
      async openTranscriptionDetail (transcription) {
        this.isLoading = true;
        const transcriptionInfo = await transcriptionService.getTranscription(
          transcription.id
        );
        this.isLoading = false;
        this.$router.push({
          name: 'Editor',
          params: {
            transcriptionInfo,
            transcription,
            path: `${config.url}/stt/v1/transcript/${transcription.id}/media/stream?cookie=${this.account.user.token}`
          }
        });
      },
      async getFileDuration () {
        this.isLoading = true;
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          const audio = document.createElement('audio');
          reader.onload = (e) => {
            audio.src = e.target.result;
            const audioCtx = new AudioContext();
            audioCtx.decodeAudioData(e.target.result, (buffer) => {
              console.log(`detected ${buffer.numberOfChannels} audio channels in the selected file`);
              console.log(`duration of audio: ${buffer.duration}`);
            }).then((buffer) => {
              for (let i = 1; i <= buffer.numberOfChannels; i++) {
                this.audioChannelCount.push({ channel: i, announcer: `Locutor ${i}` });
              }
              this.audioDuration = parseInt(buffer.duration);
              this.isLoading = false;
              resolve();
            });
          };
          reader.onerror = reject;
          reader.readAsArrayBuffer(this.file);
        });
      },
      async goToNextPg () {
        this.showTranscriptionTab = false;
        this.btnNextAction = 'Transcrever';
        this.btnBackAction = 'Voltar';
      },
      async changeFile () {
        this.audioChannelCount = [];
        await this.getFileDuration();
        this.updateFileDuration();
        if (this.audioChannelCount.length > 1) {
          this.showFileProperties = true;
        } else {
          this.showFileProperties = false;
        }
      },
      async onButtonBackActionClick () {
        if (this.showTranscriptionTab) {
          await this.hideModal();
        } else if (this.multiChannelChecked === 'accepted') {
          this.fileName = this.file.name;
          this.showTranscriptionTab = true;
          this.btnNextAction = 'Avançar';
          this.btnBackAction = 'Fechar';
        }
      },
      async onButtonNextActionClick () {
        if (this.multiChannelChecked === 'accepted' && this.showTranscriptionTab) {
          if (!this.checkFormValidity()) {
            return;
          }
          await this.goToNextPg();
        } else {
          await this.handleModalOk();
        }
      },
      updateFileDuration () {
        this.formatedFileDuration = formatingSecondToDate(this.audioDuration);
      },
      onKnowMoreClick () {
        this.$refs.accuracyModal.show();
      },
      async hideAcurracyModal () {
        this.$refs.accuracyModal.hide();
      },
      async hideModal () {
        this.$nextTick(() => {
          this.$refs.modal.hide();
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
.section-content {
  display: 1;

  .list-container {
    margin-left: 20px;
    margin-right: 20px;
    flex: 1;

      .header-container {
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: #F5F8FA;
          border-radius: 4px;

          .search-container {
              margin-right: 4%;
          }
      }

    .btn-custom {
      background-color: #bee7bd00;
      display: flex;
      border: 1px;
      margin-left: auto;
      margin-right: 0;
    }

    .item-table {
      border: none !important;
      background-color: none;
      cursor: pointer;
    }
  }

    .table {
        margin-top: 15px;
        background-color: #FFFFFF;
        border-radius: 4px;
        margin-bottom: 0px;
        /deep/thead th {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 40px;
            letter-spacing: 0em;
            text-align: left;
            background: #F5F8FA;
            color: #6278A3;
            width: 40%;

            border-radius: 4px 4px 0px 0px;
            padding: 0.5rem;
            padding-left: 24px;
        }

        /deep/tbody td {
            font-size: 14px;
            padding: 0.5rem;
            padding-left: 24px;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            color: #6278A3;
            font-weight: 400;
            line-height: 40px;
            letter-spacing: 0em;
            text-align: left;
        }
    }

    .pagination-container {
        background-color: #FFFFFF;
        display: flex;
        flex-flow: row;
        align-content: center;
        alignment: center;
        padding-left: 35%;
    }

    .new-user-button {
        margin-left: 2%;
        background: #207BDD;
        border-radius: 4px;
        border-color: #207BDD;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;

        &:hover {
            background-color: #b5dcb1;
            transition: background-color 0.2s linear;
        }
    }

}
.btn-primary {
  background-color: #207BDD;
  border:none;
  width: 134px;
  margin-right: -15px;
  height: 44px;
  margin-top: 15px;
}
.btn-transparent {
  background-color: #EBEFF2;
  color: #6278A3;
  font-family: 'Poppins';
  font-weight: 600;
  border: none;
  width: 134px;
  margin-right: 20px;
  height: 44px;
  margin-top: 15px;
}
.multichannelAudio {
  color: #6278A3;
  font-size: 16px;
  font-family: Poppins;
}
.form-control {
  font-weight: 400;
  font-family: Poppins;
}

::-moz-placeholder {  /* Firefox 19+ */
   color: #6278A3;
}

.bv-no-focus-ring {
  font-weight: 400;
  font-family: Poppins;
  font-size: 16px;
  line-height: 25px;
}
.aboutAccuracy {
  line-height: 18px;
  font-size: 16px;
  font-weight: bold;
  font-family: Poppins;
}
h4 {
  padding-bottom: 18px;
}
.channels {
  font-size:14px;
  height: 36px;
  margin-left: 10px;
  width: 675px !important;
  border-radius: 3px;
}
.nav-item > li{
  border: none !important;
}
.knowMore {
  color: #207BDD;
  font-weight: 200;
  font-size: 16px;
  font-family: Poppins;
  margin-left: 30px;
  font-size: 14px
}
.modal-header {
  padding: none !important;
}
.tab-bold {
  color: #6278A3;
  font-weight: 600;
  font-size: 16px;
  font-family: Poppins;
}
.tab {
  color: #6278A3;
  font-size: 16px;
  font-family: Poppins;
}
.btn-close {
  width: 190px;
}
footer {
  text-align: right;
  margin-right: 25px;
  margin-bottom: 15px;
}
</style>
